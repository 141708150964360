/* eslint-disable @next/next/no-img-element */
import React from "react";
import { isEmpty, camelCase } from "lodash";
import { useRouter } from "next/router";
import {
  XIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  BellIcon,
} from "@heroicons/react/outline";
import {
  Image,
  Pressable,
  Modal,
  Popover,
  Tooltip,
} from "@gonoodle/gn-universe-ui";
import { SECTIONS_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { useInView } from "@gonoodle/gn-universe-utils";
import {
  useProfile,
  useOnSiteMessagesQuery,
  useOnSiteMessagesMutation,
} from "../../hooks";
import { useLogEvent } from "../../contexts/Analytics";
import {
  getSourcePageAndSourcePageTypeFromReferrer,
  getUtmParamsFromQueryParams,
} from "../../utils/analytics";
import Link from "../Link";
import { useUser } from "../../contexts/user";
import { useSDKStatus } from "../../contexts/OneTrust";
import { ROUTE_PREFIX, MESSAGE_TYPES, MESSAGE_STATUSES } from "../../constants";

function getPageContentTypeAndContentId(path, params = {}) {
  let contentType = undefined;
  let contentId = undefined;
  switch (path) {
    case "/":
      contentType = "Page";
      contentId = "homePage";
      break;
    case `/${ROUTE_PREFIX.VIDEOS}/[...identity]`:
      contentType = "VideoVersion";
      [contentId] = params.identity;
      break;
    case `/${ROUTE_PREFIX.ACTIVITIES}/[...identity]`:
      contentType = "Activity";
      [contentId] = params.identity;
      break;
    case `/${ROUTE_PREFIX.TAGS}/[...identity]`:
      contentType = "ContentTag";
      [contentId] = params.identity;
      break;
    case `/${ROUTE_PREFIX.GAMES}/[...identity]`:
      contentType = "Game";
      [contentId] = params.identity;
      break;
    case `/${ROUTE_PREFIX.CURRICULUM}/[id]`:
      contentType = "Curriculum";
      contentId = params.id;
      break;
    case `/${ROUTE_PREFIX.CURRICULUM}/[id]/[competency]`:
      contentType = "Competency";
      contentId = params.competency;
      break;
    default:
      contentType = "Page";
      contentId = camelCase(path.replace(/^\//, ""));
      break;
  }
  return { contentType, contentId };
}

function Growl({
  prompt,
  messageId,
  status,
  action = {},
  image,
  textTheme,
  internalTitle,
  partnerName,
  onDismiss = () => {},
}) {
  const router = useRouter();
  const { profile } = useProfile();
  const contentParams = getPageContentTypeAndContentId(
    router.pathname,
    router.query,
  );
  const { updateMessagesStatus } = useOnSiteMessagesMutation(contentParams);

  const analyticsEventProperties = {
    sourceElement: SECTIONS_TYPES.GROWL,
    sourceName: prompt,
    title: internalTitle.toLowerCase(),
    partnership: partnerName,
    ...getSourcePageAndSourcePageTypeFromReferrer(
      router.pathname,
      router.query,
    ),
    ...getUtmParamsFromQueryParams(router.query),
    gradeId: profile?.grade.id,
    size: profile?.size,
    schoolId: profile?.school.id,
    url: action.url,
  };

  useLogEvent({
    event: "Announcement banner shown",
    properties: {
      ...analyticsEventProperties,
    },
    options: {
      enabled: !!prompt,
    },
  });

  React.useEffect(() => {
    if (status === MESSAGE_STATUSES.UNSEEN) {
      updateMessagesStatus([
        { onSiteMessageId: messageId, action: MESSAGE_STATUSES.DISPLAYED },
      ]);
    }
  }, [messageId, status, updateMessagesStatus]);

  const dismissMessage = () => {
    updateMessagesStatus([
      { onSiteMessageId: messageId, action: MESSAGE_STATUSES.DISMISSED },
    ]);

    onDismiss();
  };

  return (
    <div className="isolate relative flex flex-col min-w-[320px] group">
      {action.url && (
        <Link
          to={action.url}
          className="absolute inset-0 z-10 outline-none"
          events={[
            {
              event: "Announcement banner clicked",
              properties: {
                ...analyticsEventProperties,
              },
            },
          ]}
          queryParams={{
            referrer: {
              sourceElement: SECTIONS_TYPES.GROWL,
              sourceName: action.name,
              ...getSourcePageAndSourcePageTypeFromReferrer(
                router.pathname,
                router.query,
              ),
            },
          }}
        />
      )}

      <div className="relative h-[320px] overflow-hidden">
        <Image
          className="w-full h-full object-cover transform transition duration-200 group-hover:scale-110"
          sources={image}
          alt=""
        />

        <div className="absolute inset-0 bg-gradient-to-b from-[#18181B7A]" />

        <Pressable
          onPress={dismissMessage}
          className="absolute top-3 right-3 w-5 h-5 text-white z-20"
        >
          <XIcon />
        </Pressable>
      </div>

      <div
        className={twMerge(
          "flex flex-col mt-6 px-6 space-y-4",
          textTheme === "light" ? "text-white" : "text-gray-900",
        )}
      >
        <div className="text-[26px] leading-[20px] font-extrabold font-display">
          {prompt}
        </div>

        <div className="text-sm">{action.name}</div>
      </div>
    </div>
  );
}

function Growls({ growls = [], anchorBounds }) {
  const {
    isBlocked: isOneTrustBlocked,
    isBannerOpen: isOneTrustBannerOpen,
  } = useSDKStatus();
  const { user } = useUser();
  const { hasSelectedProfile } = useProfile();
  // Avoids rendering from the growls array directly, so we can keep showing the growls even when there status changes to displayed.
  const [unseenGrowls, setUnseenGrowls] = React.useState(
    growls.filter(({ status }) => status === MESSAGE_STATUSES.UNSEEN),
  );
  const [currentGrowl, setCurrentGrowl] = React.useState(unseenGrowls[0]);
  const slideshowIntervalRef = React.useRef(null);

  const canShowGrowl =
    !(isOneTrustBlocked === false && isOneTrustBannerOpen) &&
    user.isLoggedIn &&
    hasSelectedProfile &&
    currentGrowl &&
    unseenGrowls.length > 0;

  React.useEffect(() => {
    const newUnSeenGrowls = growls.filter(
      ({ status }) => status === MESSAGE_STATUSES.UNSEEN,
    );

    setUnseenGrowls((prevGrowls) => {
      // In case one of the was deleted while the user was seeing it, for example when a stale tab is active again.
      const filteredPrevGrowls = prevGrowls.filter((prevGrowl) =>
        growls.some(
          (growl) => growl.onSiteMessageId === prevGrowl.onSiteMessageId,
        ),
      );

      const updatedGrowls = [
        ...filteredPrevGrowls,
        ...newUnSeenGrowls.filter(
          (newGrowl) =>
            !filteredPrevGrowls.some(
              (prevGrowl) =>
                prevGrowl.onSiteMessageId === newGrowl.onSiteMessageId,
            ),
        ),
      ];

      // Ensure currentGrowl is still valid, that case when a growl was deleted while the user was seeing it.
      if (
        currentGrowl &&
        !updatedGrowls.some(
          (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
        )
      ) {
        setCurrentGrowl(updatedGrowls[0]); // Set to the first of the updated list if currentGrowl is no longer valid
      }

      return updatedGrowls;
    });
  }, [currentGrowl, growls]);

  React.useEffect(() => {
    if (!currentGrowl && unseenGrowls.length > 0) {
      setCurrentGrowl(unseenGrowls[0]);
    }
  }, [unseenGrowls, currentGrowl]);

  const navigateToGrowl = React.useCallback(
    (direction) => {
      const currentIndex = unseenGrowls.findIndex(
        (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
      );
      let newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

      // Wrap around the navigation
      if (newIndex >= unseenGrowls.length) newIndex = 0;
      if (newIndex < 0) newIndex = unseenGrowls.length - 1;

      setCurrentGrowl(unseenGrowls[newIndex]);
    },
    [currentGrowl, unseenGrowls],
  );

  const onDismiss = React.useCallback(() => {
    setCurrentGrowl(undefined);
    setUnseenGrowls([]);

    if (slideshowIntervalRef.current) {
      clearInterval(slideshowIntervalRef.current);
    }
  }, []);

  const startSlideshow = React.useCallback(() => {
    if (slideshowIntervalRef.current) {
      clearInterval(slideshowIntervalRef.current);
    }

    slideshowIntervalRef.current = setInterval(() => {
      navigateToGrowl("next");
    }, 5000); // change growl every 5 seconds
  }, [navigateToGrowl]);

  React.useEffect(() => {
    if (canShowGrowl && unseenGrowls.length > 1) {
      startSlideshow();
    }

    return () => {
      if (slideshowIntervalRef.current) {
        clearInterval(slideshowIntervalRef.current);
      }
    };
  }, [canShowGrowl, startSlideshow, unseenGrowls.length]);

  if (!canShowGrowl) {
    return null;
  }

  const currentGrowlIndex = unseenGrowls.findIndex(
    (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
  );

  return (
    <Modal
      isOpen={true}
      isDismissable={true}
      className="justify-end items-start"
      onClose={onDismiss}
    >
      {/**
       * Removing pointer-events-none from the container to avoid breaking the modal click outside to close functionality,
       * as this container takes the whole screen and the modal is not able to detect the click outside.
       * TODO: Recreate the modal component to handle this case.
       */}
      <div className="absolute inset-0 container py-4 pointer-events-none">
        <div
          className="flex flex-col max-w-[320px] rounded-lg overflow-x-hidden mx-auto md:mx-0 md:ml-auto pointer-events-auto"
          style={{
            transform: `translate(0px, ${anchorBounds.bottom}px)`,
            backgroundColor: `#${currentGrowl.backgroundColor}`,
          }}
        >
          <motion.div
            initial={false}
            animate={{ x: `${currentGrowlIndex * -100}%` }}
            transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}
            className="flex flex-row"
          >
            {unseenGrowls.map((growl) => (
              <Growl
                key={growl.onSiteMessageId}
                messageId={growl.onSiteMessageId}
                prompt={growl.message}
                status={growl.status}
                action={{
                  name: growl.ctaPhrase,
                  url: growl.destinationUrl,
                }}
                image={growl.popoverDisplayImage}
                textTheme={growl.textTheme}
                partnerName={growl.partner.partnerName}
                internalTitle={growl.internalTitle}
                onDismiss={onDismiss}
              />
            ))}
          </motion.div>

          {unseenGrowls.length > 1 && (
            <div className="flex flex-row justify-center items-center mt-4 w-full space-x-2">
              <Pressable
                className="outline-none"
                onPress={() => {
                  navigateToGrowl("previous");
                  startSlideshow(); // Restart the slideshow after manual navigation
                }}
              >
                <ArrowLeftIcon className="w-5 h-5 mr-4 text-gray-600 shrink-0" />
              </Pressable>

              {unseenGrowls.map((growl) => (
                <Pressable
                  key={growl.onSiteMessageId}
                  onPress={() => {
                    setCurrentGrowl(growl);
                    startSlideshow(); // Restart the slideshow after manual navigation
                  }}
                  className={twMerge(
                    "w-[10px] h-[10px] rounded-full shrink-0 transition-colors duration-700",
                    growl.onSiteMessageId === currentGrowl.onSiteMessageId
                      ? "bg-purple-500"
                      : "bg-gray-400",
                  )}
                />
              ))}

              <Pressable
                className="outline-none"
                onPress={() => {
                  navigateToGrowl("next");
                  startSlideshow(); // Restart the slideshow after manual navigation
                }}
              >
                <ArrowRightIcon className="ml-4 w-5 h-5 text-gray-600 shrink-0" />
              </Pressable>
            </div>
          )}

          <div className="mt-6" />
        </div>
      </div>
    </Modal>
  );
}

function Banner({
  prompt,
  action = {},
  color,
  sponsor = {},
  internalTitle,
  partnerName,
  textTheme,
}) {
  const router = useRouter();
  const [isVisible, setIsVisible] = React.useState(true);
  const { profile } = useProfile();

  const analyticsEventProperties = {
    sourceElement: SECTIONS_TYPES.BANNER,
    sourceName: prompt,
    partnership: partnerName,
    title: internalTitle.toLowerCase(),
    ...getSourcePageAndSourcePageTypeFromReferrer(
      router.pathname,
      router.query,
    ),
    ...getUtmParamsFromQueryParams(router.query),
    gradeId: profile?.grade.id,
    size: profile?.size,
    schoolId: profile?.school.id,
    url: action.url,
  };

  useLogEvent({
    event: "Announcement banner shown",
    properties: {
      ...analyticsEventProperties,
    },
    options: {
      enabled: !!prompt,
    },
  });

  return (
    <div
      className={twMerge(
        "flex-row justify-around px-4 py-3 md:px-6 md:py-4",
        textTheme === "light" ? "text-white" : "text-gray-900",
        isVisible ? "flex" : "hidden",
      )}
      style={{
        backgroundColor: `#${color}`,
      }}
    >
      <div className="flex-1 flex flex-col md:flex-row justify-center mr-4">
        <div>
          <span className="text-sm lg:text-md">{prompt}</span>

          {action.name && action.url && (
            <Link
              to={action.url}
              className="text-sm lg:text-md whitespace-nowrap underline ml-1"
              events={[
                {
                  event: "Announcement banner clicked",
                  properties: {
                    ...analyticsEventProperties,
                  },
                },
              ]}
              queryParams={{
                referrer: {
                  sourceElement: SECTIONS_TYPES.BANNER,
                  sourceName: action.name,
                  ...getSourcePageAndSourcePageTypeFromReferrer(
                    router.pathname,
                    router.query,
                  ),
                },
              }}
            >
              {action.name}
            </Link>
          )}
        </div>

        {sponsor.logo && (
          <div className="flex flex-row items-center">
            <div className="hidden md:block bg-purple-100 w-[1px] h-full rounded mx-6" />

            <span className="text-[10px] md:text-[11px] font-bold leading-6 whitespace-nowrap">
              Powered by
            </span>

            <span className="h-3 w-20 md:w-36 md:h-7">
              <Image
                className="w-full h-full object-contain"
                sources={sponsor.logo}
                alt={sponsor.name || ""}
              />
            </span>
          </div>
        )}
      </div>

      <Pressable
        onPress={() => setIsVisible(false)}
        className="w-6 h-6 self-center"
      >
        <XIcon />
      </Pressable>
    </div>
  );
}

const Notification = ({
  onSiteMessageId,
  backgroundColor,
  textTheme,
  status,
  logo,
  logoAltText,
  ctaPhrase,
  message,
  onNotificationViewed,
}) => {
  const { ref: inViewRef, inView } = useInView({ threshold: 0.9 });

  React.useEffect(() => {
    if (inView && status === MESSAGE_STATUSES.UNSEEN) {
      onNotificationViewed(onSiteMessageId);
    }
  }, [inView, status, onSiteMessageId, onNotificationViewed]);

  return (
    <div
      ref={inViewRef}
      className={twMerge(
        `w-full h-[110px] px-3 flex-shrink-0`,
        textTheme === "light" ? "text-white" : "text-gray-900",
      )}
      style={{ backgroundColor: `#${backgroundColor}` }}
    >
      <div className="flex h-full items-center py-[6px]">
        <div className="flex h-full w-2 pt-[13px] align-top">
          <div
            className={twMerge(
              `bg-gold rounded-full h-2 w-2`,
              status === MESSAGE_STATUSES.UNSEEN ? "block" : "hidden",
            )}
          />
        </div>
        {logo && (
          <Image
            sources={logo}
            alt={logoAltText || ""}
            className="ml-[6px] w-[72px] h-[72px] object-cover rounded-[4px]"
          />
        )}

        <div className="ml-3 flex flex-col space-y-[6px]">
          <p className="text-sm font-bold">{ctaPhrase}</p>
          <p className="text-xs">{message}</p>
        </div>
      </div>
    </div>
  );
};

export function NotificationCenter() {
  const router = useRouter();
  const { profile } = useProfile();
  const contentParams = getPageContentTypeAndContentId(
    router.pathname,
    router.query,
  );
  const [notificationViewedArray, setNotificationViewedArray] = React.useState(
    [],
  );
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const {
    onSiteMessages = [],
    isOnSiteMessagesLoading,
    onSiteMessagesError,
  } = useOnSiteMessagesQuery(contentParams);
  const { updateMessagesStatus } = useOnSiteMessagesMutation(contentParams);

  const { logEvent: logNotificationCenterOpenedEvent } = useLogEvent({
    event: "Notification Center Opened",
    properties: {
      ...getSourcePageAndSourcePageTypeFromReferrer(
        router.pathname,
        router.query,
      ),
      ...getUtmParamsFromQueryParams(router.query),
    },
  });

  if (isOnSiteMessagesLoading || onSiteMessagesError) return null;

  const notificationArray = onSiteMessages.filter((onSiteMessage) =>
    [MESSAGE_TYPES.NOTIFICATION_CENTER, MESSAGE_TYPES.GROWL].includes(
      onSiteMessage.displayType,
    ),
  );

  const hasUnseenNotifications = notificationArray.some(
    (onSiteMessage) => onSiteMessage.status === MESSAGE_STATUSES.UNSEEN,
  );

  const handleNotificationViewed = (onSiteMessageId) => {
    if (
      notificationViewedArray.every(
        (notification) => notification.onSiteMessageId !== onSiteMessageId,
      )
    ) {
      setNotificationViewedArray([
        ...notificationViewedArray,
        { onSiteMessageId, action: MESSAGE_STATUSES.DISPLAYED },
      ]);
    }
  };

  const onNotificationCenterOpen = () => {
    setIsNotificationsOpen(true);
    logNotificationCenterOpenedEvent();
  };

  const onNotificationCenterClose = () => {
    setIsNotificationsOpen(false);

    if (!isEmpty(notificationViewedArray)) {
      updateMessagesStatus(notificationViewedArray);
    }

    setNotificationViewedArray([]);
  };

  return (
    <Popover.Root
      offset={40}
      placement="bottom"
      isOpen={isNotificationsOpen}
      onOpen={onNotificationCenterOpen}
      onClose={onNotificationCenterClose}
    >
      <Tooltip.Root delay={0} isDisabled={!isEmpty(notificationArray)}>
        <Popover.Trigger className="relative focus:outline-none">
          {hasUnseenNotifications && (
            <div className="absolute bg-[#ffb81d] h-2 w-2 rounded-full top-[3px] left-[14px]" />
          )}

          <BellIcon className="text-white mr-4 h-6 w-6" aria-hidden="true" />
        </Popover.Trigger>

        <Tooltip.Content
          placement="bottom"
          className="absolute top-0 mt-8 mr-4"
        >
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold">Notifications</h2>
            <p className="text-sm text-gray-500">Coming soon!</p>
          </div>
        </Tooltip.Content>
      </Tooltip.Root>

      {isEmpty(notificationArray) ? null : (
        <Popover.Content className="flex flex-col w-screen px-6 md:w-[400px] lg:w-[327px] xl:w-[400px] !z-30 focus:outline-none">
          <svg
            className="absolute top-[-14px] left-[52%] md:left-[45%] z-50"
            width="30"
            height="14"
            viewBox="0 0 30 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4547 8.55791C15.4678 6.4334 15.7607 3.94259 15.563 0.820944C18.5753 2.27464 20.8823 3.67549 22.9612 5.56783C25.0709 7.48822 26.9669 9.93429 29.12 13.5H3.59545C8.62888 11.9581 11.6848 10.4258 13.4547 8.55791Z"
              fill="#18181B"
              stroke="#71717A"
            />
          </svg>
          <div className="flex flex-col overflow-y-scroll rounded-md !max-h-[550px] border-[1px] bg-gray-500 gap-[1px] border-gray-500">
            {notificationArray.map((onSiteMessage, index) =>
              onSiteMessage.destinationUrl ? (
                <Link
                  key={index}
                  className="focus:ring focus:ring-purple focus:outline-none"
                  to={onSiteMessage.destinationUrl}
                  events={[
                    {
                      event: "Announcement banner clicked",
                      properties: {
                        sourceElement: SECTIONS_TYPES.NOTIFICATION_CENTER,
                        sourceName: onSiteMessage.ctaPhrase,
                        title: onSiteMessage.internalTitle.toLowerCase(),
                        partnership: onSiteMessage.partner.partnerName,
                        ...getSourcePageAndSourcePageTypeFromReferrer(
                          router.pathname,
                          router.query,
                        ),
                        ...getUtmParamsFromQueryParams(router.query),
                        gradeId: profile?.grade.id,
                        size: profile?.size,
                        schoolId: profile?.school.id,
                        url: onSiteMessage.destinationUrl,
                      },
                    },
                  ]}
                  queryParams={{
                    referrer: {
                      sourceElement: SECTIONS_TYPES.NOTIFICATION_CENTER,
                      sourceName: onSiteMessage.ctaPhrase,
                      ...getSourcePageAndSourcePageTypeFromReferrer(
                        router.pathname,
                        router.query,
                      ),
                    },
                  }}
                >
                  <Notification
                    onNotificationViewed={handleNotificationViewed}
                    {...onSiteMessage}
                  />
                </Link>
              ) : (
                <Notification
                  key={index}
                  onNotificationViewed={handleNotificationViewed}
                  {...onSiteMessage}
                />
              ),
            )}
          </div>
        </Popover.Content>
      )}
    </Popover.Root>
  );
}

export function OnSiteMessages({ anchorBounds }) {
  const router = useRouter();
  const contentParams = getPageContentTypeAndContentId(
    router.pathname,
    router.query,
  );
  const {
    onSiteMessages = [],
    isOnSiteMessagesLoading,
    onSiteMessagesError,
  } = useOnSiteMessagesQuery(contentParams);

  if (isOnSiteMessagesLoading || onSiteMessagesError) return null;

  const growls = onSiteMessages.filter(
    ({ displayType }) => displayType === MESSAGE_TYPES.GROWL,
  );
  const banner = onSiteMessages.find(
    ({ displayType }) => displayType === MESSAGE_TYPES.BANNER,
  );

  return (
    <>
      {!router.pathname.startsWith(`/${ROUTE_PREFIX.CURRICULUM}/`) && banner && (
        <Banner
          prompt={banner.message}
          action={{
            name: banner.ctaPhrase,
            url: banner.destinationUrl,
          }}
          sponsor={{
            name: banner.logoAltText,
            logo: banner.logo,
          }}
          internalTitle={banner.internalTitle}
          partnerName={banner.partner.partnerName}
          textTheme={banner.textTheme}
          color={banner.backgroundColor}
        />
      )}

      <Growls growls={growls} anchorBounds={anchorBounds} />
    </>
  );
}
