export default [
  {
    name: "Facebook",
    href: "https://www.facebook.com/GoNoodleInc",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.954 24h.01-.01zm.085 0h-.004.01-.006zm-.121 0h.011-.012zm.16 0h-.007.011-.004zm-.196 0h.008-.008zm.234 0h-.006.008-.002zm.039-.001h-.003.01-.007zm-.318 0h.01-.01zm.357 0h-.007.013-.006zm-.394 0h.013H11.8zm.432-.001h-.008.012-.004zm-.468 0h.012-.012zm-.044-.001h.01-.01zm.55 0h.01-.01zm-.588-.001h.014-.015zm.627 0h-.005.014-.008zm.04-.001h-.01.014-.005zm-.703 0h.015-.015zm-.033-.001h.01-.019.009zm.774 0h-.01.019-.01zm.038-.001h-.005.016-.01zm-.861 0h.016-.016zm-.035-.002h.017-.017zm.935 0h-.01.017-.007zm-.97-.002l.017.001h-.017zm1.008 0l-.013.001h.017-.004zm-1.056-.002l.018.001H11.446zm1.094.001h-.004.018-.014zm-1.128-.002h.018-.019zm1.167 0h-.009.018-.01zm-1.202-.002l.019.001h-.019zm1.24 0l-.013.001h.019-.006zm.038-.002l-.016.001h.014l.018-.002-.015.001zm-1.308 0l.014.001h-.017l-.015-.002.018.002zm1.347-.002h-.008.02-.012zm-1.4 0h.02-.019zm-.033-.003l.019.002-.012-.001h-.007zm1.471.001h-.012.02-.008zm-1.503-.003l.017.002-.016-.001-.016-.001h.015zm1.541 0l-.016.002.017-.002h.015-.016zm.038-.002h-.005.018-.013zm-1.629 0h.018-.018zm-.034-.003l.018.001h-.01l-.008-.001zm1.702 0l-.01.001.018-.001h-.008zm-1.735-.002h.016-.016zm1.773 0h-.013.016-.003zm-1.819-.004l.015.001h-.014zm1.857.001h-.004.015-.011zm.038-.003h-.007.014-.007zm-1.93 0h.015-.015zm-.034-.003h.013-.012zm2.002 0h-.009.012H13zm-2.039-.003h.004-.004zm2.077 0h.003-.003zm.038-.004h-.004.009-.005zm-2.155 0h.009H10.92zm-.035-.003h.006-.006zm2.228 0h-.004.006-.002zm-2.988-.095C4.391 22.953 0 17.985 0 12 0 5.377 5.377 0 12 0s12 5.377 12 12c0 5.985-4.391 10.953-10.125 11.854V15.47h2.796L17.203 12h-3.328V9.749c0-.949.465-1.874 1.956-1.874h1.513V4.922s-1.374-.234-2.686-.234c-2.742 0-4.533 1.66-4.533 4.668V12H7.078v3.469h3.047v8.385z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/gonoodle/?hl=en",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm0 4.5c-2.037 0-2.292.009-3.092.045-.799.037-1.344.163-1.82.349a3.677 3.677 0 00-1.33.865 3.677 3.677 0 00-.864 1.328c-.186.477-.312 1.022-.349 1.82C4.51 9.708 4.5 9.964 4.5 12s.009 2.292.045 3.092c.037.798.163 1.344.349 1.82.191.494.448.912.865 1.33.417.416.835.673 1.328.864.477.186 1.022.312 1.82.349.8.036 1.056.045 3.093.045s2.292-.009 3.092-.045c.798-.037 1.344-.163 1.82-.349a3.677 3.677 0 001.33-.865c.416-.417.673-.835.864-1.328.186-.477.312-1.023.349-1.82.036-.8.045-1.056.045-3.093s-.009-2.292-.045-3.092c-.037-.799-.163-1.344-.349-1.82a3.676 3.676 0 00-.865-1.33 3.677 3.677 0 00-1.328-.864c-.477-.186-1.023-.312-1.82-.349-.8-.036-1.056-.045-3.093-.045zm0 1.351c2.003 0 2.24.008 3.03.044.732.033 1.129.156 1.393.258.35.136.6.299.863.561.262.263.425.513.56.863.103.264.226.661.259 1.392.036.791.044 1.028.044 3.031s-.008 2.24-.044 3.03c-.033.732-.156 1.129-.258 1.393-.136.35-.299.6-.561.863a2.323 2.323 0 01-.863.56c-.264.103-.661.226-1.392.259-.791.036-1.028.044-3.031.044s-2.24-.008-3.03-.044c-.732-.033-1.129-.156-1.393-.258-.35-.136-.6-.299-.863-.561a2.322 2.322 0 01-.56-.863c-.103-.264-.226-.661-.259-1.392-.036-.791-.044-1.028-.044-3.031s.008-2.24.044-3.03c.033-.732.156-1.129.258-1.393.136-.35.299-.6.561-.863.263-.262.513-.425.863-.56.264-.103.661-.226 1.392-.259.791-.036 1.028-.044 3.031-.044zm0 2.298a3.851 3.851 0 100 7.702 3.851 3.851 0 000-7.702zm0 6.351a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm4.904-6.503a.9.9 0 11-1.8 0 .9.9 0 011.8 0z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "Twitter",
    href: "https://twitter.com/GoNoodle",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zM9.886 18.382c5.31 0 8.214-4.4 8.214-8.213 0-.126 0-.252-.006-.371A5.916 5.916 0 0019.537 8.3a5.86 5.86 0 01-1.659.455 2.885 2.885 0 001.27-1.598 5.845 5.845 0 01-1.832.7 2.887 2.887 0 00-4.915 2.634 8.189 8.189 0 01-5.95-3.017c-.246.425-.39.922-.39 1.449 0 1 .509 1.885 1.287 2.4a2.836 2.836 0 01-1.305-.359v.036c0 1.4.994 2.562 2.317 2.832a2.88 2.88 0 01-1.305.047 2.882 2.882 0 002.694 2.006 5.803 5.803 0 01-4.275 1.191 8.043 8.043 0 004.412 1.305z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "YouTube",
    href: "https://www.youtube.com/channel/UC2YBT7HYqCbbvzu3kKZ3wnw",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm7.187 8.39a1.878 1.878 0 00-1.327-1.327C16.69 6.75 12 6.75 12 6.75s-4.69 0-5.86.314A1.879 1.879 0 004.812 8.39C4.5 9.56 4.5 12 4.5 12s0 2.44.313 3.61c.173.646.681 1.154 1.326 1.327 1.17.313 5.861.313 5.861.313s4.69 0 5.86-.313a1.878 1.878 0 001.327-1.327C19.5 14.44 19.5 12 19.5 12s0-2.44-.313-3.61zM10.5 14.25v-4.5L14.397 12 10.5 14.25z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "Pinterest",
    href: "https://www.pinterest.com/gonoodlebreaks/",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          d="M12 .024c-6.627 0-12 5.373-12 12 0 5.084 3.163 9.43 7.627 11.179-.105-.95-.2-2.41.042-3.447.218-.936 1.407-5.964 1.407-5.964s-.36-.719-.36-1.782c0-1.668.968-2.914 2.172-2.914 1.024 0 1.518.769 1.518 1.69 0 1.03-.655 2.57-.994 3.996-.282 1.194.6 2.168 1.777 2.168 2.133 0 3.773-2.249 3.773-5.495 0-2.873-2.065-4.882-5.013-4.882-3.414 0-5.418 2.561-5.418 5.208 0 1.031.397 2.137.893 2.738a.36.36 0 01.083.344c-.091.38-.293 1.194-.333 1.361-.053.22-.174.266-.402.16-1.498-.697-2.435-2.888-2.435-4.648 0-3.786 2.75-7.262 7.928-7.262 4.163 0 7.398 2.966 7.398 6.93 0 4.136-2.607 7.465-6.227 7.465-1.216 0-2.359-.632-2.75-1.378 0 0-.602 2.291-.748 2.852-.27 1.043-1.002 2.35-1.491 3.146 1.123.348 2.316.535 3.553.535 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/gonoodle",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zM8.248 18.744V9.373H5.133v9.371h3.115zm11.232 0V13.37c0-2.879-1.536-4.218-3.586-4.218-1.652 0-2.393.91-2.807 1.548V9.373H9.972c.041.879 0 9.371 0 9.371h3.115V13.51c0-.28.02-.56.103-.76.224-.56.737-1.139 1.598-1.139 1.126 0 1.578.86 1.578 2.119v5.014h3.114zM6.712 4.854c-1.066 0-1.763.7-1.763 1.62 0 .898.675 1.618 1.721 1.618h.02c1.087 0 1.763-.72 1.763-1.619-.02-.918-.676-1.62-1.741-1.62z"
          fill="#fff"
        />
      </svg>
    ),
  },
];
